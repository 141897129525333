<template lang="pug">
    .flex
        .w-30.h-30.border-1.flex.items-center.justify-center.bg-white.cursor-pointer(@click="onCheck" :class="{'bg-red-100': error}")
            template(v-if="isChecked")
                img.block(:src="ICONS.checkmark")
        .text-sm.ml-15
            span(v-html="label")
        
</template>

<script>
import { ICONS } from '@/configs/icons'

export default {
    name: "Checkbox",
    props: {
        name: {
            type: String,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isChecked: false,
        }
    },
    computed: {
        ICONS() {
            return ICONS
        },
    },
    methods: {
        onCheck() {
            this.isChecked = !this.isChecked
            this.$emit('value', this.isChecked)
        }
    }
}
</script>
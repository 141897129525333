<template lang="pug">
    div
        label.text-label.font-cormorantBold(v-if="label") {{ label }}
        textarea( 
            class="h-full w-full p-2 text-black caret-slate-300 border-1 rounded border-white outline-none focus:border-gray-300"
            :class="{'bg-red-100': error}"
            @input="onValue"
            v-model="text_input"
            :placeholder="placeholder"
        )
</template>

<script>
export default {
    name: "TextArea",
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            text_input: ''
        }
    },
    methods: {
        onValue() {
            this.$emit('value', this.text_input)
        }
    },
    watch: {
        value: {
            handler(val) {
                this.text_input = val 
            },
            immediate: true,
        }
    }
}
</script>

<style lang="scss" scoped>
textarea {
    resize: none;
    font-size: .85rem;
}
</style>    
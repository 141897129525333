<template lang="html">
  <div id="adfox_170601812038667344"></div>
</template>

<script>
export default {
  name: "Adfox",
  created() {
    if(window.yaContextCb) {
      window.yaContextCb.push(()=>{
        window.Ya.adfoxCode.create({
          ownerId: 397306,
          containerId: 'adfox_170601812038667344',
          params: {
            p1: 'davns',
            p2: 'hoce'
          }
        })
      })
    }
  }
}
</script>
<template lang="pug">
    div
        label.text-label.font-cormorantBold(:for="name") {{ label }}
        select.app-select(
            :name="name" 
            :id="name" 
            @input="onValue"
            class="text-sm text-gray-600 w-full mt-1 p-3 caret-slate-300 border-1 rounded border-white outline-none focus:border-gray-300 bg-white"
            :class="{'bg-red-100': error}"
        )
            template(v-for="item in options")
                option(:key="item.value" :disabled="item.disabled" :selected="item.selected") {{ item.name }}
</template>

<script>
export default {
    name: "Select",
    props: {
        name: {
            type: String,
        },
        id: {
            type: String,
        },
        label: {
            type: String,
        },
        options: {
            type: Array,
            default: () => [],
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onValue({ target }) {
            this.$emit('value', target.value)
        }
    }
}
</script>
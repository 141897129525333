<template lang="pug">
    .flex.flex-col.w-full
        label.text-label.font-cormorantBold {{ FORM.files_label  }}
        div.text-label.font-cormorantBold(class="w-full 768:w-500") {{ FORM.files_desc }}
        .flex(class="flex-col 1024:flex-col")
            .flex.flex-col.mr-10(v-if="filelist.length")
                template(v-for="file in filelist")
                    .flex.min-h-45.w-full.p-2.bg-white.mb-5(class="1024:w-300")
                        .w-full.flex.justify-between
                            .flex.w-full
                                img.block.w-20.mr-10(:src="IMGS.doc_icon")    
                                .flex.flex-col.w-full
                                    .text-sm.mb-1.text-black
                                        span {{file.name}}
                                    .relative.w-full.h-progress-bar.bg-red-100
                                        .app-file-progress.absolute.bg-green-500.h-progress-bar.w-full
                            .flex.pl-10
                                img.cursor-pointer(:src="IMGS.close_icon" @click="onDelete")
            div
                .relative.w-full.border-dashed.border-2.bg-white.mb-10(
                    @dragover="dragover" 
                    @dragleave="dragleave" 
                    @drop="drop"
                    class="768:w-500 768:h-200"
                )
                    .p-10.border.border-black.flex.justify-center.items-center.w-full(class="768:p-0 768:h-200 768:absolute" @click="onFile")
                        .flex.items-center(class="768:flex-col")
                            template(v-if="isLoading")
                                loader
                            template(v-else)
                                img.block.cursor-pointer(:src="IMGS.doc_icon" class="768:mb-10")
                                .text-sm.text-black.ml-10(class="768:ml-0")
                                    span(class="hidden 768:block") {{ FORM.grab_files }}
                                    span(class="block 768:hidden") {{ FORM.grab_files_mobile }}
                        input(type="file" ref="file_upload" style="display: none;" @input="onFileChange")
                    input(
                        type="file" 
                        name="fields[assetsFieldHandle][]" 
                        id="assetsFieldHandle" 
                        class="w-px h-px opacity-0 overflow-hidden absolute" 
                        @change="onChange" 
                        ref="file" 
                    )

                template(v-if="fileSizeError || fileTypeError")
                        .inline-flex.bg-red-100.p-20.mb-20.w-full
                            .text-sm {{ fileSizeError }}
                            .text-sm {{ fileTypeError }}

                .text-xs(v-html="FORM.files_note")
</template>

<script>
//Components
import LoaderStyleOne from '@/components/common/loaders/style-1';
//Store
import { FilesNameSpace, FilesActionTypes, FilesGetterTypes } from '@/store/files/types';
//Config
import { FORM } from '@/configs/text'
//Helers
import {formatBytes} from '@/helpers/format-bytes'

export default {
    name: "FileDragAndDrop",
    components: {
        'loader': LoaderStyleOne,
    },
    data() {
        return {
            filelist: [],
            fileSizeError: '',
            fileTypeError: '',
            isLoading: false,
        }
    },
    computed: {
        IMGS() {
            return {
                doc_icon: require('./assets/doc.svg'),
                close_icon: require('./assets/close.svg'),
            }
        },
        FORM() {
            return FORM
        },
        loadedFiles() {
            return this.$store.getters[`${FilesNameSpace}/${FilesGetterTypes.GetLoadedFiles}`]
        },
    },
    methods: {
        formatBytes() {
            return formatBytes()
        },
        onChange() {
            // this.filelist.push(...this.$refs.file.files);
            this.onLoadFile(this.$refs.file.files)
        },
        remove(i) {
            this.filelist.splice(i, 1);
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('bg-gray-300')) {
                event.currentTarget.classList.remove('bg-gray-100');
                event.currentTarget.classList.add('bg-gray-300');
            }
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-gray-300');
        },
        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); // Trigger the onChange event manually
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-gray-300');
        },
        onFile() {
            this.$refs['file_upload'].click()
        },
        onFileChange() {
            // this.filelist.push(...this.$refs['file_upload'].files)
            this.onLoadFile(this.$refs['file_upload'].files)
        },
        onFileSizeValidation(size) {
            if (size > 104857600) {
                this.fileSizeError = 'Размер файла превышает 100MB.'
                return false
            } else {
                return true
            }
        },
        onFileTypeValidation(type) {
            if (['video/mp4', 'image/jpeg', 'image/png', 'image/jpg', 'video/quicktime'].includes(type)) {
                return true
            } else {
                this.fileTypeError = 'Неверный тип файла.'
                return false
            }
        },
        async onLoadFile(files) {
            if (this.onFileTypeValidation(files[0].type) && this.onFileSizeValidation(files[0].size)) {
                try {
                    this.isLoading = true
                    const form_data = new FormData()
                    form_data.append('files', ...files)
                    const response = await this.$store.dispatch(`${FilesNameSpace}/${FilesActionTypes.AddFile}`, { files: form_data })
                    if (!response.data.error_message) {
                        const { title, description } = this.$data
                        const { _id, name, type, size, path } = this.loadedFiles
                        await this.$store.dispatch(`${FilesNameSpace}/${FilesActionTypes.AddFileDetails}`, { 
                                details: {
                                    title,
                                    description,
                                    file_id: _id,
                                    user_id: '630b4a65acebe36eee971d7c',
                                    name,
                                    type,
                                    size,
                                    path,
                                } 
                            }
                        )
                        
                        this.filelist.push(this.loadedFiles)
                    }
                } catch (error) {
                    this.notifyDefaultError({ error })
                } finally {
                    this.isLoading = false
                }
            }
        },
        async onDelete(file) {
            // await this.onDeleteFile(file)
            this.remove(this.filelist.indexOf(file))
        },
        async onDeleteFile({_id}) {
            await this.$store.dispatch(`${FilesNameSpace}/${FilesActionTypes.DeleteFile}`, { id: _id })
        }
    },
    watch: {
        filelist: {
            handler(val) {
                this.$emit('value', val)
            },
            deep: true,
        }
    }
}
</script>

<style lang="scss">
.app-file-progress {
    border-radius: 500px !important;
    box-shadow: 0px 1px 4px rgba(red, .5);
}
</style>
const PENCIL = require('../static/icons/SVG/006-pencil.svg');
const FOLDER = require('../static/icons/SVG/048-folder.svg');
const PLUS = require('../static/icons/SVG/267-plus.svg')
const CHECKMARK = require('../static/icons/SVG/273-checkmark.svg');
const CANCEL = require('../static/icons/SVG/270-cancel-circle.svg');
const CIRCLE_RIGHT = require('../static/icons/SVG/323-circle-right.svg');
const BIN = require('../static/icons/SVG/173-bin.svg');
const LOOP = require('../static/icons/SVG/303-loop2.svg');
const CROSS = require('../static/icons/SVG/272-cross.svg');

const ICONS = {
    pencil: PENCIL,
    folder: FOLDER,
    plus: PLUS,
    checkmark: CHECKMARK,
    cancel_circle: CANCEL,
    circle_right: CIRCLE_RIGHT,
    bin: BIN,
    loop: LOOP,
    cross: CROSS,
}

export { ICONS }
<style>
@font-face {
    font-family: 'TT Norms';
    font-weight: 300;
    src: url(./assets/fonts/TTNorms-Light.woff) format('woff'),url(./assets/fonts/TTNorms-Light.woff2) format('woff2')
}
@font-face {
    font-family: 'TT Norms';
    font-weight: 500;
    src: url(./assets/fonts/TTNorms-Medium.woff) format('woff'),url(./assets/fonts/TTNorms-Medium.woff2) format('woff2')
}
@font-face {
    font-family: 'TT Norms';
    font-weight: 400;
    src: url(./assets/fonts/TTNorms-Regular.woff) format('woff'),url(./assets/fonts/TTNorms-Regular.woff2) format('woff2')
}
@font-face {
    font-family: 'TT Norms';
    font-weight: 700;
    src: url(./assets/fonts/TTNorms-Bold.woff) format('woff'),url(./assets/fonts/TTNorms-Bold.woff2) format('woff2')
}
@font-face {
    font-family: 'Druk Wide Cyr';
    font-weight: 700;
    src: url(./assets/fonts/DrukWideCyr-Bold.woff) format('woff'),url(./assets/fonts/DrukWideCyr-Bold.woff2) format('woff2')
}
@font-face {
    font-family: 'Druk Wide Cyr';
    font-weight: 500;
    src: url(./assets/fonts/DrukWideCyr-Medium.woff) format('woff'),url(./assets/fonts/DrukWideCyr-Medium.woff2) format('woff2')
}

@font-face {
    font-family: 'Druk Wide Cyr';
    font-weight: 900;
    src: url(./assets/fonts/DrukCondensedCyrSuper.woff) format('woff'),url(./assets/fonts/DrukCondensedCyrSuper.woff2) format('woff2')
}
html {font-size: 20px;line-height: 1.2;font-family: 'TT Norms', Verdana, sans-serif;}
body {font-size: 20px;line-height: 1.2;font-family: 'TT Norms', Verdana, sans-serif;}
div.bg-main {background: #332784;color: #fff;}
.b-form {max-width:500px;width:100%;}
.b-form__drag-n-drop {margin-bottom:1.25rem;}
.b-form__drag-n-drop label.text-label {font-family:'TT Norms', Verdana, sans-serif;display: block;font-weight: 500;margin-bottom: 0.5rem;}
.b-form__drag-n-drop div.text-label {font-family:'TT Norms', Verdana, sans-serif;display: block;font-weight:400;font-size:0.75rem;margin-bottom:0.5rem;}
.b-form__drag-n-drop .bg-red-100 {padding:0 0 0.5rem;font-size:0.5rem !important;font-weight:normal !important;color:#FF0000;background:none;}
.b-form__inputtext {margin-bottom:1.25rem;}
.b-form__inputtext.mb-0 {margin-bottom:0;}
.b-form__inputtext label {font-family:'TT Norms', Verdana, sans-serif;display: block;font-weight: 500;margin-bottom: 0.5rem;}
.b-form__inputtext.small-label label {font-family:Verdana, sans-serif;display: block;font-weight: 400;font-size:0.75rem;margin-bottom: 0.5rem;}
.b-form__inputtext input {background: rgba(255,255,255,.08);border: 1px solid rgba(255,255,255,0);border-radius: 1rem;color: rgba(255,255,255,.5);display:inline-block;font-size: 1rem;padding: 1.25rem;width: 100%;margin:0;border:0 !important;outline:0 !important;}
.b-form__inputtext input.bg-red-100 {border-bottom:1px solid #D4266E !important}
.b-form__inputselect {margin-bottom:1.25rem;overflow:hidden;}
.b-form__inputselect label {font-family:Verdana, sans-serif;display: block;font-weight: 500;margin-bottom: 0.5rem;}
.b-form__inputselect select {appearance: none;background: rgba(255,255,255,.08) url('./assets/arrow-select.svg');background-size:24px;background-repeat:no-repeat;background-position:right 1rem center;border: 1px solid rgba(255,255,255,0);border-radius: 1rem;color: rgba(255,255,255,.5);display:inline-block;font-size: 1rem;padding: 1.25rem 2.5rem 1.25rem 1.25rem;width: 100%;overflow:hidden;margin:0;border:0 !important;outline:0 !important;}
.b-form__inputselect select option {appearance: none;font-size:1rem;color:black;width:100%;max-width:500px;overflow:hidden;text-overflow:ellipsis;padding:inherit;margin:inherit;white-space:normal;}
.b-form__button {width: 100%;padding: 19px 20px;border: 0;cursor: pointer;background: #D4266E;color: #fff;border-radius: 5rem;}
.b-errortext {padding:1.25rem 0;color:#FF0000}
.b-oktext {padding:1.25rem 0;color:#00FF00}
.b-title {font-weight: 700;margin-bottom: 0.75rem;margin-top: 4rem;text-align: center;text-transform: uppercase;width: 100%;font-family: 'Druk Wide Cyr',Verdana,sans-serif;font-size: 1.5rem;}
#adfox_170601812038667344 img {margin: 0 auto;}
</style>
<template lang="pug">
    .relatives.flex.flex-col.items-center(class="pl-20 pr-20")
        .w-full(class="1280:w-1160")
        screen-wrapper
            template(v-slot:title)
                adfox.w-full
                h1(class="b-title") Прием заявок окончен
            template(v-slot:content)
                section.mt-40(class="b-form")
                    //p Прием заявок окончен
                    //.flex.justify-center(class="flex-col")
                    //    text-input(
                    //        :label="FORM.firstname"
                    //        class="b-form__inputtext"
                    //        @value="form.firstname = $event"
                    //        :value="form.firstname"
                    //        :error="validation.firstname"
                    //    )
                    //    text-input(
                    //        :label="FORM.lastname"
                    //        class="b-form__inputtext"
                    //        @value="form.lastname = $event"
                    //        :value="form.lastname"
                    //        :error="validation.lastname"
                    //    )
                    //    text-input(
                    //        :label="FORM.patronymic"
                    //        class="b-form__inputtext"
                    //        @value="form.patronymic = $event"
                    //        :value="form.patronymic"
                    //        :error="validation.patronymic"
                    //    )
                    //.flex.justify-center(class="flex-col")
                    //    text-input(
                    //        :label="FORM.date_of_birth"
                    //        class="b-form__inputtext"
                    //        @value="form.date_of_birth = $event"
                    //        :value="form.date_of_birth"
                    //        :error="validation.date_of_birth"
                    //        mask="##.##.####"
                    //    )
                    //.flex
                    //  select-option.w-full(
                    //    label="Вуз"
                    //    :options="FORM.vuz_list"
                    //    class="b-form__inputselect"
                    //    @value="form.vuz = $event"
                    //    :error="validation.vuz"
                    //  )
                    //.flex
                    //  select-option.w-full(
                    //    label="Ссуз"
                    //    :options="FORM.ssyz_list.sort((a,b) => a.value.localeCompare(b.value))"
                    //    class="b-form__inputselect"
                    //    @value="form.ssyz = $event"
                    //    :error="validation.ssyz"
                    //  )
                    //.flex
                    //  select-option.w-full(
                    //    :label="FORM.competitive"
                    //    :options="FORM.competitive_topics"
                    //    class="b-form__inputselect"
                    //    @value="form.competitive = $event"
                    //    :error="validation.competitive"
                    //  )
                    //.flex.justify-center(class="flex-col")
                    //    text-input(
                    //      :label="FORM.phone"
                    //      class="b-form__inputtext"
                    //      @value="form.phone = $event"
                    //      :value="form.phone"
                    //      :error="validation.phone"
                    //      mask="+7(###) ###-##-##"
                    //    )
                    //    text-input(
                    //      :label="FORM.social"
                    //      class="b-form__inputtext"
                    //      @value="form.social = $event"
                    //      :value="form.social"
                    //      :error="validation.social"
                    //    )
                    //.flex.justify-center(class="flex-col b-form__drag-n-drop")
                    //    drag-n-drop(@value="onFile")
                    //.flex.justify-center(class="flex-col")
                    //  text-input(
                    //    :label="FORM.links"
                    //    class="b-form__inputtext small-label mb-0"
                    //    @value="form.links = $event"
                    //    :value="form.links"
                    //    :error="validation.links"
                    //  )
                    //  .text-xs.italic.mt-1.mb-4 *Не забудь открыть доступ к просмотру
                    //.flex.justify-center(class="flex-col b-inputcheckbox")
                    //    checkbox(
                    //        :label="FORM.agreement"
                    //        @value="onCheck"
                    //        :error="validation.agreement"
                    //
                    //    )
                    //template(v-if="errorText")
                    //    .inline-flex(class="b-errortext")
                    //        .text-sm {{ errorText }}
                    //
                    //template(v-if="successText")
                    //    .inline-flex(class="b-oktext")
                    //        .text-sm {{ successText }}
                    //
                    //.flex.items-center.flex-col(class="")
                    //    regular-button(
                    //        class="b-form__button mt-4"
                    //        :value="!isSending ? BUTTONS_VALUES.apply : 'идет отправка...'"
                    //        @click="!isSending ? onApply() : false"
                    //    )

</template> 

<script>
//Components
import BaseScreenWrapper from '@/components/views/home/components/BaseScreenWrapper';
import Adfox from '@/components/common/adfox/adfox.vue';
import TextInput from '@/components/common/forms/text-input';
import SelectOption from '@/components/common/forms/select';
import TextArea from '@/components/common/forms/text-area';
import DragAndDrop from '@/components/common/forms/drag-n-drop-files';
import Checkbox from '@/components/common/forms/checkbox';
import RegularButton from '@/components/common/buttons/regular-button';
//Config
import { FORM } from '@/configs/text';
import { BUTTONS_VALUES } from '@/configs/names';
import { isMobile } from '@/helpers/is-mobile'
//Store
import { UserPostsNameSpace, UserPostsActionTypes } from '@/store/user-posts/types';

export default {
    name: "ApplyComponent",
    components: {
        'screen-wrapper': BaseScreenWrapper,
        'text-input': TextInput,
        'select-option': SelectOption,
        'text-area': TextArea,
        'drag-n-drop': DragAndDrop,
        'checkbox': Checkbox,
        'regular-button': RegularButton,
        'adfox': Adfox,
    },
    data() {
        return {
            form: {
                firstname: '',
                lastname: '',
                patronymic: '',
                date_of_birth: '',
                vuz: '',
                ssyz: '',
                competitive: '',
                phone: '',
                social: '',
                links: '',
                attachment: [],
                agreement: false,
            },
            validation: {
                firstname: false,
                lastname: false,
                patronymic: false,
                date_of_birth: false,
                vuz: false,
                ssyz: false,
                competitive: false,
                phone: false,
                social: false,
                links: false,
                agreement: false,
            },
            errorText: '',
            successText: '',
            isOther: false,
            isSending: false,
        }
    },
    computed: {
        IMGS() {
            return {
                title: require('./assets/title-white.svg'),
                arrow_back: require('./assets/arrow-white.svg'),
            }
        },
        OPTIONS() {
            return [
                { name: 'Например, малый бизнес', value: '', disabled: true, selected: true },
                { name: 'idea', value: 'Бизнес идея' }
            ]
        },
        FORM() {
            return FORM
        },
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        isMobile() {
            return isMobile()
        }
    },
    methods: {
        onValidation() {
            let errors = 0

            for(const key in Object.keys(this.validation)) {
              this.validation[key] = false;
            }

            ['firstname', 'lastname', 'date_of_birth', 'competitive', 'phone', 'social'].forEach((key) => {
              if (this.form[key] === '') {
                this.validation[key] = true;
                errors++;
              }
            });

            if (this.form.agreement === false) {
              this.validation.agreement = true;
              errors++;
            }

            if(this.form.vuz === '' && this.form.ssyz === '') {
              this.validation.vuz = true;
              this.validation.ssyz = true;
              errors++;
            }

            if(this.form.attachment.length === 0 && this.form.links === '' ) {
              this.validation.links = true;
              errors++;
            }

            return errors === 0 ? false : true
        },
        onBackTop() {
            this.onGTM({ name: 'nazad', eventAction: 'element_click', actionGroup: 'interactions' })
            this.onBack()
        },
        onBackBottom() {
            this.onGTM({ name: 'vernutsya_na_glavnuu', eventAction: 'element_click', actionGroup: 'interactions' })
            this.onBack()
        },
        onBack() {
            if (this.isMobile) {
                this.$router.push('/')
            } else {
                window.open('/', '_self')
            }
        },
        onGTM({ name, eventAction, actionGroup }) {
            const dataLayer = window.dataLayer = window.dataLayer || []; 
            dataLayer.push({
                'event': 'vntZayav',
                'eventCategory' : 'zayavka',
                'eventAction' : eventAction,
                'eventLabel' : name,
                'eventValue' : null,
                'screenName': '/apply',
                'buttonLocation': null,
                'actionGroup': actionGroup,   
                'touchPoint': 'web'
            });
        },
        isEmpty() {
            return !this.text ? false : true
        },
        onCheck(value) {
            this.form.agreement = value
        },
        async onApply() {
            this.onGTM({ name: 'podat_zayavku', eventAction: 'button_click', actionGroup: 'interactions' })
            this.errorText = ''
            if (!this.onValidation()) {
                try {
                    this.isSending = !this.isSending
                    const data = await this.$store.dispatch(`${UserPostsNameSpace}/${UserPostsActionTypes.AddUserPost}`, { 
                            ...this.form,
                            title: this.isOther ? this.form.title_other : this.form.title
                        }
                    )
                    if (!data.error_message) {
                        if(window.stream_land) {
                          window.stream_land('tL0GSZGkyHF5pkw=');
                        }
                        if(window._tmr) {
                          window._tmr.push({ type: 'reachGoal', id: 3386022, goal: 'reg'});
                        }
                        this.successText = 'Заявка отправлена'
                        this.form = {
                            firstname: '',
                            lastname: '',
                            patronymic: '',
                            date_of_birth: '',
                            city: '',
                            social: '',
                            email: '',
                            phone: '',
                            title: '',
                            title_other: '',
                            text: '',
                            experience: '',
                            problems: '',
                            family: '',
                            hobby: '',
                            spend: '',
                            fact: '',
                            links: '',
                            attachment: [],
                            agreement: false,
                        }
                        this.onGTM({ name: 'uspeshnaya_zayavka', eventAction: 'confirmed', actionGroup: 'conversions' })
                        setTimeout(() => {
                             this.onBack()
                        }, 5000)
                    } else {
                        this.errorText = data.error_message
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    this.isSending = !this.isSending
                }
            } else {
                this.errorText = 'Заполните обязательные поля'
            }
        },
        onFile(value) {
            this.form.attachment = [...value.map(item => item.file_id)]
        },
    },
    watch: {
        form: {
            handler(val) {
                if (val.title === 'Другое') {
                    this.isOther = true
                } else {
                    this.isOther = false
                    this.form.title_other = ''
                }
            },
            deep: true,
        }
    }
}
</script>
